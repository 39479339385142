import React from 'react';
import { Field } from 'formik'



///// THE TUX /////

// Home Address !!!
// Add to Package.json AND also in .env
const homeDir = "https://mrtuxbeta.tailorwise.com"


export const backAppId = "IBOpAxqm0UIv03IJuEQN1ANVbgw0vrI21zIk57Wh"
export const backJSId = "ZvVYjQFqwuJC4klofxx5Manhe8c8zOSXGtx3JrKm"

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY

export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/tw-main/"
export const clientName = "Mr Tux"

export const clientPhone = "+91-09999999999999"
export const clientEmail = "info@tailorwise.com"
export const clientEmailFromId = "no-reply@tailorwise.com"  //TW no-reply-email or client validated email with us
export const clientCCEmail = ""

export const hasFactory = true
export const isSingleLocation = false
export const hasAgents = false
export const usesVendorViaFactory = false    // New Stage Factory_Recieved_For_Vendor added on 2-Aug-2021 - Also putting this as a flag in product as- usedVendorViaFactory & in business check usesVendorViaFactory

export const showOnlyClientFeature = false
export const useClientCid = true

// Specific Cofigurations

// Customer Info
export const config_cust_captureAddress = false
export const config_cust_captureProfession = false

export const clientWebsite = "https://tailorwise.com"
export const clientTermsAndConditionsUrl = "https://tailorwise.com/terms-conditions"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/MrTux/mrtux-140x30%402x.png"
export const clientMenuLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/MrTux/mrtux-140x30%402x.png"

export const currencySymbol = "₹"
export const currencyCode = "INR"
export const clientBGHome = ""
export const clientBGHero = ""



export const client_defaultCountry = 'IN (+91)'
export const client_defaultCountryOption = () => {
    return (
        <option value={client_defaultCountry} >{client_defaultCountry}</option>
    )
}

export const client_favCountryList = () => {

    // FIRST OPTION SHOULD BE SAME AS DEFAULT COUNTRY
    return (
        <>
            {client_defaultCountryOption()}
            <option value='US (+1)'  >US (+1)</option>
            <option value='CA (+1)'  >CA (+1)</option>
            <option value='AU (+61)'  >AU (+61)</option>
            <option value='GB (+44)'  >GB (+44)</option>
            <option value='AE (+971)'  >AE (+971)</option>
            <option value='ZW (+263)'  >ZW (+263)</option>
            <option value='ZA (+27)' >ZA (+27)</option>
        </>
    )

}

export const ProductType = {

    Shirt                   : "SHIRT",
    DesignerShirt           : "DESIGNER SHIRT",
    Kurta                   : "KURTA",
    DesignerKurta           : "DESIGNER KURTA",
    CoatPant                : "COAT PANT",
    CoatSuit3Piece          : "COAT SUIT 3 PIECE",
    CoatSuit4Piece          : "COAT SUIT 4 PIECE",
    CoatSuit5Piece          : "COAT SUIT 5 PIECE",
    DesignerCoatSuit        : "DESIGNER COAT SUIT",
    JodhpuriSuit            : "JODHPURI SUIT",
    DesignerJodhpuriSuit    : "DESIGNER JODHPURI SUIT",
    SafariSuit              : "SAFARI SUIT",
    IndoWestern             : "INDO WESTERN",
    FrontOpenIndoWestern    : "FRONT OPEN INDO WESTERN",
    DesignerIndoWestern     : "DESIGNER INDO WESTERN",
    JwaharJacket            : "JWAHAR JACKET",
    DesignerJwaharJacket    : "DESIGNER JWAHAR JACKET",
    Sherwani                : "SHERWANI",
    DesignerSherwani        : "DESIGNER SHERWANI",
    Trouser                 : "TROUSER",
    DesignerTrouser         : "DESIGNER TROUSER",
    ChuridarPajami          : "CHURIDAR PAJAMI",
    Pajama                  : "PAJAMA",
    Haram                   : "HARAM",
    Pagdi                   : "PAGDI",
    Stall                   : "STALL",
    Mala                    : "MALA",
    Footwear                : "FOOTWEAR",
    Accessories             : "ACCESSORIES",
    WaistCoat               : "WAIST COAT",
    Blazer                  : "BLAZER",
    Other                   : "OTHER"
    // // Added by us as per products in existing orders 
    // WAIST COAT
    // SUIT 2 PIECE
    // SUIT 3 PIECE

    // // Others Needed
    // COAT 
    // BLAZER
    // STITCH SERVICE 

}


export const productType_allValues = () => {
    var products = []
    // <option>{ProductType.None}</option>

    products.push(ProductType.Shirt               )
    products.push(ProductType.DesignerShirt       )
    products.push(ProductType.Kurta               )
    products.push(ProductType.DesignerKurta       )
    products.push(ProductType.CoatPant            )
    products.push(ProductType.CoatSuit3Piece      )
    products.push(ProductType.CoatSuit4Piece      )
    products.push(ProductType.CoatSuit5Piece      )
    products.push(ProductType.DesignerCoatSuit    )
    products.push(ProductType.JodhpuriSuit        )
    products.push(ProductType.DesignerJodhpuriSuit)
    products.push(ProductType.SafariSuit          )
    products.push(ProductType.IndoWestern         )
    products.push(ProductType.FrontOpenIndoWestern)
    products.push(ProductType.DesignerIndoWestern )
    products.push(ProductType.JwaharJacket        )
    products.push(ProductType.DesignerJwaharJacket)
    products.push(ProductType.Sherwani            )
    products.push(ProductType.DesignerSherwani    )
    products.push(ProductType.Trouser             )
    products.push(ProductType.DesignerTrouser     )
    products.push(ProductType.ChuridarPajami      )
    products.push(ProductType.Pajama              )
    products.push(ProductType.Haram               )
    products.push(ProductType.Pagdi               )
    products.push(ProductType.Stall               )
    products.push(ProductType.Mala                )
    products.push(ProductType.Footwear            )
    products.push(ProductType.Accessories         )
    products.push(ProductType.WaistCoat           )
    products.push(ProductType.Blazer              )
    products.push(ProductType.Other               )

    products.sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    })

    return products
}



// //// PRODUCTS OFFERED 
// export const ProductType_original = {

//     Sherwani: "SHERWANI",D
//     Suit2piece: "SUIT 2 PIECE",
//     Suit3piece: "SUIT 3 PIECE",
//     Coat: "COAT",

//     Blazer: "BLAZER",
//     SafariSuit: "SAFARI SUIT",

//     Shirt: "SHIRT",D
//     Trouser: "TROUSER",
//     WaistCoat: "WAIST COAT",
//     JawaharJacket: "JAWAHAR JACKET",
//     KurtaPyjama: "KURTA PYJAMA",
//     IndoWestern: "INDO WESTERN",
//     JODHPURI: "JODHPURI",
//     CowlKurta: "COWL KURTA",
//     Tuxedoe: "TUXEDOE",
//     //    case BlazerSports = "BLAZER SPORTS"
//     //    case BlazerSafari = "BLAZER SAFARI"

//     Kurta: "KURTA",D
//     Pyjama: "PYJAMA",
//     StitchService: "STITCH SERVICE",
//     Fabric: "FABRIC",
//     None: 'None',
//     Accessories: 'ACCESSORIES',
//     NeckWear: 'NECKWEAR',
//     Denim: 'DENIM',
//     GolfTrouser: 'GOLF TROUSER',
//     Jacket: 'JACKET',
//     BomberJacket: 'BOMBER JACKET',
//     FieldJacket: 'FIELD JACKET',
//     SportJacket: 'SPORT JACKET',
//     OverCoat: 'OVER COAT',
//     // WaistCoat: 'WAIST COAT',
//     Polo: 'POLO',
//     Pant: 'PANT',
//     Bag: 'BAG',
//     Shoes: 'SHOES',

//     // Senator : "SENATOR",
//     // CasualShirts : "CASUAL SHIRTS",
//     // CasualPants : "CASUAL PANTS",
//     // DanshikiWear : "DANSHIKI WEAR",
//     // AgbadaSet : "AGBADA SET",
//     // Shorts : "SHORTS",
//     // NativeCap : "NATIVE CAP"


//     //    Stich Service (instead of MTO)
//     //    Jawahar Jacket (instead of JAWAHAR JACKET)
//     //    JODHPURI (instead of Bandhgala)
//     //    Blazer (single)
//     //    Safari Suit

// }


// export const productType_allValues_original = () => {
//     var products = []
//     // <option>{ProductType.None}</option>
//     products.push(ProductType.Tuxedoe)
//     products.push(ProductType.Suit2piece)
//     products.push(ProductType.Suit3piece)
//     products.push(ProductType.Coat)
//     products.push(ProductType.Shirt)
//     products.push(ProductType.Trouser)
//     products.push(ProductType.Blazer)
//     products.push(ProductType.WaistCoat)
//     products.push(ProductType.Sherwani)
//     products.push(ProductType.IndoWestern)
//     products.push(ProductType.SafariSuit)
//     products.push(ProductType.KurtaPyjama)
//     products.push(ProductType.Kurta)
//     products.push(ProductType.Pyjama)
//     products.push(ProductType.CowlKurta)
//     products.push(ProductType.StitchService)
//     products.push(ProductType.Fabric)
//     products.push(ProductType.Accessories)
//     products.push(ProductType.NeckWear)
//     products.push(ProductType.Denim)
//     products.push(ProductType.GolfTrouser)
//     products.push(ProductType.Jacket)
//     products.push(ProductType.BomberJacket)
//     products.push(ProductType.FieldJacket)
//     products.push(ProductType.SportJacket)
//     products.push(ProductType.OverCoat)
//     products.push(ProductType.Polo)
//     products.push(ProductType.Pant)
//     products.push(ProductType.Bag)
//     products.push(ProductType.Shoes)

//     // products.push(ProductType.Senator)
//     // products.push(ProductType.CasualShirts)
//     // products.push(ProductType.CasualPants)
//     // products.push(ProductType.DanshikiWear)
//     // products.push(ProductType.AgbadaSet)
//     // products.push(ProductType.Shorts)
//     // products.push(ProductType.NativeCap)
    


//     products.sort(function (a, b) {
//         if (a < b) { return -1; }
//         if (a > b) { return 1; }
//         return 0;
//     })

//     return products
// }


export const ProductType_options_NonFormik = (name, classNames, onChangeFunc, value, showNone) => {
    return (
        <select name={name} className={'select ' + classNames} value={value ?? ''} onChange={onChangeFunc}>

            { showNone ? <option>{ProductType.None}</option> : null }

            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}


        </select>
    )
}


export const ProductType_options = (name, classNames, onChangeFunc) => {
    return (
        <Field as='select' name={name} className={'select ' + classNames} onChange={onChangeFunc}>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}
        </Field>
    )
}


export const productType_isGroup = (type) => {
    switch (type) {
        case ProductType.Suit2piece: case ProductType.Suit3piece: case ProductType.KurtaPyjama: case ProductType.Sherwani: case ProductType.IndoWestern: case ProductType.Tuxedoe: case ProductType.SafariSuit: return true
        default: return false
    }
}


export const productType_groupProducts = (type) => {
    switch (type) {
        case ProductType.Sherwani: return [ProductType.Sherwani, ProductType.Kurta, ProductType.Pyjama]
        case ProductType.Suit2piece: return [ProductType.Coat, ProductType.Trouser]
        case ProductType.Suit3piece: return [ProductType.Coat, ProductType.Trouser, ProductType.WaistCoat]
        case ProductType.KurtaPyjama: return [ProductType.Kurta, ProductType.Pyjama]
        case ProductType.IndoWestern: return [ProductType.IndoWestern, ProductType.Trouser]
        case ProductType.Tuxedoe: return [ProductType.Tuxedoe, ProductType.Trouser]
        case ProductType.SafariSuit: return [ProductType.SafariSuit, ProductType.Trouser]
        default: return []
    }
}


export const StyleSelectionProductType_options_NonFormik = (name, classNames, onChangeFunc, value) => {
    return (
        <select name={name} className={'select has-background-grey-dark has-text-white ' + classNames} value={value ?? ''} onChange={onChangeFunc}>
            <option>{"All"}</option>
            {productType_allValues().map((thisVal) => {
                return <option>{thisVal}</option>
            })}

        </select>
    )
}